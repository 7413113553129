// pages/About.js

import React from 'react';
import './pages.css';

const About = () => {
  return (

    <div className = 'pageBodyBed'>
      <div className = 'pageBodyContainer'>
        <h1 className='pageHeader'>About Page</h1>
        <p className='pageBody'>Hoos Playing Bridge was founded in 2023 by Katie Sullivan. We have meetings ever week where we just 
          play bridge for fun and have lessons to get better at the game. A couple times per year, we host small competitions and we are also
          planning a tournament sometime in the Fall (2024) where teams from different schools will come to UVA compete (details coming soon)!
          We also attend local competitions in Charlottesville together that happen frequently.
        </p>
        <p className='pageBody'>We are also probably the only club in existence that PAYS its members to come to meetings. Yes, YOU!!!🤑🤑🤑
          (Courtesy of a bridge enthusiast). Come grab your 15 bucks 🫵😎🫵!
        </p>
      </div>
      
    </div>
    
  );
}

export default About;
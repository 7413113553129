// pages/Contact.js

import React from 'react';
import './pages.css';
import './pages.css';

const Contact = () => {
  return (
    <div className = 'pageBodyBed'>
      <div className = 'pageBodyContainer'>
        <h1 className='pageHeader'>Contact Us</h1>
        <p className='pageBody'>All are welcome to join and have fun at Bridge Club! We usually meet on Sundays (location tbd for Fall 2024)</p>
        <p className='pageBody'>For more information, reach out to Alan Hale (President): szz6tb@virginia.edu</p>
        <p className='pageBody'>Also follow us on instagram @uvabridge</p>
      </div>
      
    </div>
  );
}

export default Contact;
import React, { useState } from "react";
import "./pages.css";
import Spinner from "./Spinner"; // Import the Spinner component

const Attendance = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submissionError, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const actualDay = new Date();

    if (
      actualDay.getDay() !== 0 ||
      actualDay.getHours() < 11 ||
      actualDay.getHours() > 16
    ) {
      setError(true);
      setLoading(false);
      setTimeout(() => setError(false), 3000);
    } else {
      try {
        const formData = `fullName=${encodeURIComponent(
          fullName
        )}&email=${encodeURIComponent(email)}`;
        await fetch(
          "https://script.google.com/macros/s/AKfycbyC_--MoI9q5jS7hncrojbIN4bgqP3FclXqdS5Yz8_L95bPYnE8l62IVdL0xFvP1kJ0/exec",
          {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData,
          }
        );

        console.log("Form data sent");
        setFullName("");
        setEmail("");
        setSubmitted(true);
      } catch (error) {
        console.error("There was an error submitting the data:", error);
        alert("Error submitting data. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const today = new Date();
  while (today.getDay() !== 0) {
    today.setDate(today.getDate() - 1);
  }
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const day = today.getDate();

  return (
    <div className="pageBodyBed">
      <div className="pageBodyContainer">
        <h1 className="pageHeader">
          Attendance Form: {month}/{day}/{year}
        </h1>
        <p className="pageBody">
          We are lucky to be sponsored by a bridge enthusiast, who rewards
          regular members with $15 per meeting for attendance! Fill out the form
          below at the beginning of each meeting.
        </p>

        <div className="formContainer">
          {submitted && !loading && (
            <div className="notification">Form submitted successfully!</div>
          )}
          {submissionError && (
            <div className="errorNotification">
              Error: Currently not accepting submissions
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className={`attendenceForm ${submitted || loading ? "blur" : ""}`}
          >
            <div className="formGroup">
              <label className="formLabel">
                Full Name:
                <input
                  type="text"
                  className="formInput"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  disabled={submitted || loading}
                />
              </label>
            </div>
            <div className="formGroup">
              <label className="formLabel">
                Email Address:
                <input
                  type="email"
                  className="formInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={submitted || loading}
                />
              </label>
            </div>
            {!loading ? (
              <input
                type="submit"
                className="formSubmit"
                disabled={submitted || loading}
              />
            ) : (
              <button className="formSubmit" disabled>
                <Spinner /> {/* Display the spinner in place of the button */}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Attendance;


import React from 'react';
import './Footer.css';


const Footer = () => {
    return (
        <div className = 'footer'> 
            <p className='footerText'>Instagram: @uvabridge </p>
            <p className='footerText'>UVA Presence: https://virginia.presence.io/organization/hoos-playing-bridge </p>
            <p className='footerText'>Contact: szz6tb@virginia.edu</p>
        </div>

    );
}

export default Footer;